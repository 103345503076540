.models {
  width: 75%;
  margin: auto;
  background-color: #00000036;
  min-height: 300px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: center;

  a {
    display: flex;
    width: 25%;
    height: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    color: white;

    p {
      margin: 0 0 10px 0;
    }

    img {
      width: 100%;
      padding: 10%;
      height: 100%;
      object-fit: contain;
      box-sizing: border-box;
    }
  }
  a:hover {
    background-color: #404459a8;
  }
}

@media only screen and (max-width: 769px) {
  .models {
    justify-content: center;

    a {
      width: 33%;
    }
  }
}

@media only screen and (max-width: 481px) {
  .models {
    justify-content: center;

    a {
      width: 50%;
    }
  }
}
