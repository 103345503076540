.product {
  padding: 30px;

  .contacts {
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    svg {
      width: 20px;
      margin-right: 15px;
    }
  }

  .description {
    display: flex;
    margin-bottom: 40px;
    p {
      max-width: 40%;
      margin-left: 30px;
    }
    img {
      background-color: #00000026;
      height: auto;
      max-width: 350px;
    }
  }

  .slider {
    width: 90%;
    margin: 70px auto;
    .slick-list {
      img {
        width: 30% !important;
      }

      .slick-track {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  .product {
    .description {
      flex-direction: column;
      p {
        margin: 20px 0;
        max-width: 100%;
      }
    }

    .slider {
      .slick-list {
        img {
          width: 50% !important;
        }
      }
    }
  }
}
